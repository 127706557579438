 :root {
  --color-primary: #ef6f6cff;
  --color-secondary: #0a2342ff;
  --color-tertiary: #2ca58dff;
  --color-highlight: #84bc9cff;
  --color-background: #fffdf7ff;

  --font-size-large: 24px;
  --font-size-regular: 16px;

  --border-radius: 5px;

  --padding-small: 10px;
  --padding-regular: 15px; }

$breakpoint: 600px;

$layout-header-height: 12rem;
$layout-footer-height: 3rem;

html, body {
  font-family: basic-sans, sans-serif;
  color: #333333;
  font-size: var(--font-size-regular);
  padding: 0;
  margin: 0; }

body {
  height: 100%;
  background-color: var(--color-background); }

h1, h2, h3 {
  color: var(--color-secondary);
  margin-bottom: 0;
  margin-top: auto; }

section {
  border-bottom: thick solid var(--color-primary);
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media only screen and (max-width: #{$breakpoint}) {
    display: block; } }

a, a:visited, a:link {
  color: var(--color-tertiary);
  text-decoration: none; }
a:active, a:hover, a:focus {
  color: var(--color-highlight); }

.content {
  height: 100%;
  background-color: var(--color-background);
  width: auto;
  margin: 0 1rem;
  @media only screen and (min-width:#{$breakpoint}) {
    margin: 0 auto;
    max-width: 800px;
    display: grid;
    grid-template: "navigation" "body" "footer"; } }

header, main, footer {
  margin: 0 auto; }

header {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: navigation;
  height: #{$layout-header-height};
  width: 100%; }

nav {
  font-size: var(--font-size-large);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-gap: 1em;
  gap: 1em; }
main {
  grid-area: body;
  min-height: calc(100vh - (#{$layout-header-height} + #{$layout-footer-height})); }

footer {
  height: #{$layout-footer-height};
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center; }

section.section {
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  &:first-of-type {
    margin-top: 0; }
  &--columns {
    @media only screen and (min-width:#{$breakpoint}) {
      flex-direction: row; } }

  &__header {
    flex: 100%;
    flex-grow: 2; } }

img {
  border-radius: 50%;
  flex-basis: 33%;
  min-width: 200px;
  object-fit: contain;
  object-position: center center;
  height: auto;
  max-height: 300px; }

.accessibility {
  left: 50%;
  position: absolute;
  transform: translateY(-100%);
  color: black;
  background: yellow;
  font-weight: bold;
  text-decoration: none;
  height: 30px;
  padding: 8px;
  transition: transform 0.3s;
  &:focus {
    transform: translateY(0%); } }

.button {
  font-size: var(--font-size-regular);
  border-radius: var(--border-radius);
  padding: var(--padding-small) var(--padding-regular);

  &--cta {
    margin: 0 auto;
    display: block;
    width: 200px;
    text-align: center;
    background-color: var(--color-primary); }
  &:visited, &:link {
    color: white; }
  &:active, &:hover, &:focus {
    filter: brightness(90%);

    color: white; } }
